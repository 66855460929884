// import package
import React, { useEffect, Suspense, lazy } from "react";
// import Suspense from 'react';
// import lazy from 'react';
import { Switch, BrowserRouter, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import Contact from "./pages/ContactUs";
// import component
import ConditionRoute from "./components/Route/ConditionRoute";
import i18n from "./components/i18next/i18n";
import HelperRoute from "./components/Route/HelperRoute";
import SocketContext from "./components/Context/SocketContext";

// pages for this product
// import AdPopup from './components/Footer/AdPopup';
// import HomePage from "./pages/HomePage";
// import FeePage from "./pages/Fees";
// import LoginPage from "./pages/LoginPage";
// import P2pwallet from "./pages/P2pwallet";
// import Register from "pages/register.js";
// import ForgotPassword from "pages/forgot-password.js";
// import EmailVerification from "./pages/EmailVerification";
// import ProfilePage from "./pages/profile";
// import SettingPage from "./pages/settings";
// import Kyc from "./pages/kyc";
// import DashboardPage from "./pages/DashboardPage";
// import WalletPage from "./pages/WalletPage";
// import Staking from "./pages/staking";
// import Spot from "./pages/spot";
// import ResetPasswordPage from "./pages/ResetPasswordPage";
// import AboutUsPage from "./pages/AboutUsPage";
// import FaqPage from "./pages/FaqPage";
// import PressPage from "./pages/PressPage";
// import InvestorsPage from "./pages/InvestorsPage";
// import TermsPage from "./pages/TermsPage";
// import Disclaimer from "./pages/disclaimer";
// import Refund from "./pages/refund";
// import P2PTradeHistory from "./pages/P2ptradeHistoryPage";
// import P2P from "./pages/p2p";
// import Postmyad from "./pages/postmyad";
// import Peertopeer from "./pages/peertopeer";
// import Comingsoon from "./pages/commingsoon";
import MyAds from "./components/MyAds";
// import Chat from "./pages/p2pchat";
// import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
// import ChartPage from "./pages/chart";
import ChartAPP from "./components/SportTrade/Chart_App";
// import History from "./pages/history";
// import SupportPage from "./pages/SupportPage";
// import Launchpad from "./pages/launchpad";
// import LaunchpadDetails from "./pages/launchpaddetails";
// import RegisterThanksMsg from "./pages/register-thanks-msg";
// import RegisterThanksMsgMobile from "./pages/register-thanks-msg-mobile";
// import Cryptonews from "./pages/cryptonews";
// import Blog_description from "./pages/blog-description";
// import PublicAPI from "./pages/PublicAPI.js";

// import action
import { decodeJwt } from "./actions/jsonWebToken";

// import config
import { socket } from "./config/socketConnectivity";
// import lib
import store from "./store";
import isLogin from "./lib/isLogin";
import { getAuthToken } from "./lib/localStorage";

// const PrivacyPolicyPage = lazy(() => import('./routes/HomeComponent'));

const HomePage = lazy(() => import("./pages/HomePage"));
const FeePage = lazy(() => import("./pages/Fees"));
const LoginPage = lazy(() => import("./pages/LoginPage"));
const P2pwallet = lazy(() => import("./pages/P2pwallet"));
const Register = lazy(() => import("./pages/register"));
const ForgotPassword = lazy(() => import("./pages/forgot-password"));
const EmailVerification = lazy(() => import("./pages/EmailVerification"));
const ProfilePage = lazy(() => import("./pages/profile"));
const SettingPage = lazy(() => import("./pages/settings"));
const Kyc = lazy(() => import("./pages/kyc"));
const DashboardPage = lazy(() => import("./pages/DashboardPage"));
const WalletPage = lazy(() => import("./pages/WalletPage"));
const Staking = lazy(() => import("./pages/staking"));
const Spot = lazy(() => import("./pages/spot"));
const ResetPasswordPage = lazy(() => import("./pages/ResetPasswordPage"));
const AboutUsPage = lazy(() => import("./pages/AboutUsPage"));
const FaqPage = lazy(() => import("./pages/FaqPage"));
const PressPage = lazy(() => import("./pages/PressPage"));
const InvestorsPage = lazy(() => import("./pages/InvestorsPage"));
const TermsPage = lazy(() => import("./pages/TermsPage"));
const Anouncement = lazy(() => import("./pages/anouncement"));
const Disclaimer = lazy(() => import("./pages/disclaimer"));
const Refund = lazy(() => import("./pages/refund"));
const P2PTradeHistory = lazy(() => import("./pages/P2ptradeHistoryPage"));
const P2P = lazy(() => import("./pages/p2p"));
const Postmyad = lazy(() => import("./pages/postmyad"));
const Peertopeer = lazy(() => import("./pages/peertopeer"));
const Comingsoon = lazy(() => import("./pages/commingsoon"));
const Chat = lazy(() => import("./pages/p2pchat"));
const PrivacyPolicyPage = lazy(() => import("./pages/PrivacyPolicyPage"));
const ChartPage = lazy(() => import("./pages/chart"));
const History = lazy(() => import("./pages/history"));
const SupportPage = lazy(() => import("./pages/SupportPage"));
const Launchpad = lazy(() => import("./pages/launchpad"));
const LaunchpadDetails = lazy(() => import("./pages/launchpaddetails"));
const LaunchPadAddRequest = lazy(() => import("./pages/LaunchPadAddRequest"));
const RegisterThanksMsg = lazy(() => import("./pages/register-thanks-msg"));
const RegisterThanksMsgMobile = lazy(() =>
  import("./pages/register-thanks-msg-mobile")
);
const Cryptonews = lazy(() => import("./pages/cryptonews"));
const Blog_description = lazy(() => import("./pages/blog-description"));
const PublicAPI = lazy(() => import("./pages/PublicAPI"));
const ErrorPage = lazy(() => import("./pages/404"));

const App = () => {
  const { isAuth } = store.getState().auth;

  useEffect(() => {
    if (isAuth != true && isLogin()) {
      decodeJwt(getAuthToken(), store.dispatch);
    }
  }, []);

  return (
    <Provider store={store}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter basename="/">
          <Suspense
            fallback={
              <div>
                <i class="fas fa-spinner fa-spin"></i>
              </div>
            }
          >
            <SocketContext.Provider value={{ socket }}>
              <ToastContainer />
              <HelperRoute />
              <Switch>
                <ConditionRoute
                  exact
                  path="/"
                  component={HomePage}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/fee"
                  component={FeePage}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/register-thanks-msg"
                  component={RegisterThanksMsg}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/register-thanks-msg-m"
                  component={RegisterThanksMsgMobile}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/launchpad"
                  component={Launchpad}
                  type={"private"}
                />
                <ConditionRoute
                  exact
                  path="/launchpaddetails/:launchpadId"
                  component={LaunchpadDetails}
                  type={"private"}
                />             
                <ConditionRoute
                  exact
                  path="/dynamicTokenRequest"
                  component={LaunchPadAddRequest}
                  type={"private"}
                />

                <ConditionRoute
                  exact
                  path="/p2ptradehistory"
                  component={P2PTradeHistory}
                  type={"private"}
                />

                <ConditionRoute
                  exact
                  path="/p2p"
                  component={P2P}
                  type={"private"}
                />
                <ConditionRoute
                  exact
                  path="/postmyad"
                  component={Postmyad}
                  type={"private"}
                />
                <ConditionRoute
                  exact
                  path="/peertopeer"
                  component={Peertopeer}
                  type={"private"}
                />
                <ConditionRoute
                  exact
                  path="/myads"
                  component={MyAds}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/chat/:id"
                  component={Chat}
                  type={"public"}
                />

                <ConditionRoute
                  exact
                  path="/staking"
                  component={Staking}
                  type={"private"}
                />
                <ConditionRoute
                  exact
                  path="/history"
                  component={History}
                  type={"private"}
                />
                <ConditionRoute
                  exact
                  path="/support-ticket"
                  component={SupportPage}
                  type={"private"}
                />

                <ConditionRoute
                  exact
                  path="/login"
                  component={LoginPage}
                  type={"auth"}
                />
                <ConditionRoute
                  exact
                  path="/login/:authToken"
                  component={LoginPage}
                  type={"auth"}
                />
                <ConditionRoute
                  exact
                  path="/register"
                  component={Register}
                  type={"auth"}
                />
                <ConditionRoute
                  exact
                  path="/recover-password"
                  component={ForgotPassword}
                  type={"auth"}
                />
                <ConditionRoute
                  exact
                  path="/reset-password/:authToken"
                  component={ResetPasswordPage}
                  type={"auth"}
                />
                <ConditionRoute
                  exact
                  path="/P2pwallet"
                  component={P2pwallet}
                  type={"private"}
                />

                {/* PRIVATE */}

                <ConditionRoute
                  exact
                  path="/profile"
                  component={ProfilePage}
                  type={"private"}
                />
                <ConditionRoute
                  exact
                  path="/settings"
                  component={SettingPage}
                  type={"private"}
                />
                <ConditionRoute
                  exact
                  path="/dashboard"
                  component={DashboardPage}
                  type={"private"}
                />
                <ConditionRoute
                  exact
                  path="/wallet"
                  component={WalletPage}
                  type={"private"}
                />
                <ConditionRoute
                  exact
                  path="/kyc"
                  component={Kyc}
                  type={"private"}
                />
                {/* PRIVATE */}

                {/* PUBLIC */}
                <ConditionRoute
                  exact
                  path="/spot/:tikerRoot?"
                  component={Spot}
                  type={"public"}
                />

                <ConditionRoute
                  exact
                  path="/chart"
                  component={ChartPage}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/chartApp/:pairs/:themeData"
                  component={ChartAPP}
                  type={"public"}
                />

                <ConditionRoute
                  exact
                  path="/about"
                  component={AboutUsPage}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/faq"
                  component={FaqPage}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/press"
                  component={PressPage}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/investors"
                  component={InvestorsPage}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/terms"
                  component={TermsPage}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/anouncement"
                  component={Anouncement}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/refund"
                  component={Refund}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/disclaimer"
                  component={Disclaimer}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/comingsoon"
                  component={Comingsoon}
                  type={"public"}
                />

                <ConditionRoute
                  exact
                  path="/privacy-policy"
                  component={PrivacyPolicyPage}
                  type={"public"}
                />

                <ConditionRoute
                  exact
                  path="/email-verification/:authToken"
                  component={EmailVerification}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/withdraw-fiat-verification/:authToken"
                  component={EmailVerification}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/withdraw-coin-verification/:authToken"
                  component={EmailVerification}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/verify-old-email/:authToken"
                  component={EmailVerification}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/verify-new-email/:authToken"
                  component={EmailVerification}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/withdraw-fiat-verification/:authToken"
                  component={EmailVerification}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/withdraw-coin-verification/:authToken"
                  component={EmailVerification}
                  type={"public"}
                />

                <ConditionRoute
                  exact
                  path="/verify-newadd-email/:authToken"
                  component={EmailVerification}
                  type={"public"}
                />

                {/* <ConditionRoute
                exact
                component={AdPopup}
                type={"public"}
              /> */}

                <ConditionRoute
                  exact
                  path="/api/documentation/v1"
                  component={PublicAPI}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/contact"
                  component={Contact}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/blog/:blogid"
                  component={Blog_description}
                  type={"public"}
                />

                <ConditionRoute
                  exact
                  path="/blog"
                  component={Cryptonews}
                  type={"public"}
                />
                {/* <ConditionRoute
                exact
                path="/blog/:catid"
                component={Cryptonews}
                type={"public"}
              /> */}
                <ConditionRoute
                  exact
                  path="/blog-description"
                  component={Blog_description}
                  type={"public"}
                />
                <ConditionRoute
                  exact
                  path="/blog-description/:blogid"
                  component={Blog_description}
                  type={"public"}
                />

                <ConditionRoute
                  exact
                  path="/*"
                  component={ErrorPage}
                  type={"public"}
                />
              </Switch>
            </SocketContext.Provider>
          </Suspense>
        </BrowserRouter>
      </I18nextProvider>
    </Provider>
  );
};

export default App;
