import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// import { useRouteMatch } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import Drawer from "@material-ui/core/Drawer";
// @material-ui/icons
import Menu from "@material-ui/icons/Menu";
// core components
import styles from "assets/jss/material-kit-react/components/headerStyle.js";
import { Link } from "react-router-dom";
import store from "../../store";
import isLogin from "../../lib/isLogin";
const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const routeMatch = useRouteMatch();


  React.useEffect(() => {
    if (props.changeColorOnScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
    return function cleanup() {
      if (props.changeColorOnScroll) {
        window.removeEventListener("scroll", headerColorChange);
      }
    };
  });

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const headerColorChange = () => {
    const { color, changeColorOnScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[changeColorOnScroll.color]);
    } else {
      document.body
        .getElementsByTagName("header")[0]
        .classList.add(classes[color]);
      document.body
        .getElementsByTagName("header")[0]
        .classList.remove(classes[changeColorOnScroll.color]);
    }
  };
  const { color, rightLinks, leftLinks, brand, fixed, absolute } = props;
 // console.log("Leftlink---",leftLinks)
  const appBarClasses = classNames({
    [classes.appBar]: true,
    [classes[color]]: color,
    [classes.absolute]: absolute,
    [classes.fixed]: fixed
  });
  const brandComponent = <Link to="/" className="logo_div">{brand}</Link>;
  return (
    <AppBar className={appBarClasses}>
      <Toolbar className={classes.container}>
        {leftLinks !== undefined ? brandComponent : null}
        <div className={classes.flex}>
          {leftLinks !== undefined ? (
            <Hidden smDown implementation="css">
              {leftLinks}
            </Hidden>
          ) : (
            brandComponent
          )}
          <ul class="navbar-nav mt-2 mt-lg-0 navbar_left">
            <li class="nav-item menuicon">
              <div class="dropdown">
                <a class="btn" href="#" role="button" id="dropdownMenuIcon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {/* <i class="bi bi-list"></i> */}
                  <i class="fas fa-caret-down"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-left mainmenudropdown" aria-labelledby="dropdownMenuIcon">
                 {/* <Link to={'/staking'}> */}
                 {/* <Link to={'/comingsoon'}> */}

                  <a class="dropdown-item d-flex align-items-center" href="/staking">
                    <div class="d-flex align-items-center">
                      <div className="nav_icon_container"><i className="fas fa-chart-line"></i></div>
                      <div className="nav_menu_item_container">
                        <h6>Staking</h6>
                        <p>Crypto asset exchange</p>
                      </div>
                    </div>
                    <i class="fas fa-arrow-right"></i>
                  </a>
                  {/* </Link> */}
                  {/* <Link to={'/peertopeer'}> */}
                  {/* <Link to={'/p2p'}> */}

                  <a class="dropdown-item d-flex align-items-center" href="/p2p">
                    <div class="d-flex align-items-center">
                      <div className="nav_icon_container"><i class="fas fa-handshake"></i></div>
                      <div className="nav_menu_item_container">
                        <h6>P2P</h6>
                        <p>Peer to Peer Exchange</p>
                      </div>
                    </div>
                    <i class="fas fa-arrow-right"></i>
                  </a>
                  {/* </Link> */}
                  {/* <Link to={'/launchpad'}> */}
                  <a class="dropdown-item d-flex align-items-center" href="/launchpad">
                    <div class="d-flex align-items-center">
                    <div className="nav_icon_container"><i class="fas fa-rocket"></i></div>
                      <div className="nav_menu_item_container">
                        <h6>Launchpad</h6>
                        <p>Token Launch Platform</p>
                      </div>
                    </div>
                    <i class="fas fa-arrow-right"></i>
                  </a> 
                  {/* </Link>                  */}
                </div>
              </div>
            </li>
          </ul>
          {/* <div className="showOnlyforUsers">
            <Link to={routeMatch.path === "/spot/:tikerRoot?" ? routeMatch.url : "/spot"}>
              <button className="btn btn-outline mr-3 ml-4">Spot</button>
            </Link>
            <Link to="/derivative"><button className="btn btn-outline">Derivative</button></Link>
          </div> */}
        </div>
        <Hidden smDown implementation="css">
          {rightLinks}
        </Hidden>
        <Hidden mdUp>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            className="hamburger_btn"
          >
            <Menu />
          </IconButton>
        </Hidden>
      </Toolbar>
      <Hidden mdUp implementation="js">
        <Drawer
          variant="temporary"
          anchor={"right"}
          open={mobileOpen}
          classes={{
            paper: classes.drawerPaper + " mobile_nav"
          }}
          onClose={handleDrawerToggle}
        >
          <div className={classes.appResponsive}>
            {leftLinks}
            {rightLinks}
          </div>
        </Drawer>
      </Hidden>
    </AppBar >
  );
}

Header.defaultProp = {
  color: "white"
};

Header.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "transparent",
    "white",
    "rose",
    "dark"
  ]),
  rightLinks: PropTypes.node,
  leftLinks: PropTypes.node,
  brand: PropTypes.string,
  fixed: PropTypes.bool,
  absolute: PropTypes.bool,
  // this will cause the sidebar to change the color from
  // props.color (see above) to changeColorOnScroll.color
  // when the window.pageYOffset is heigher or equal to
  // changeColorOnScroll.height and then when it is smaller than
  // changeColorOnScroll.height change it back to
  // props.color (see above)
  changeColorOnScroll: PropTypes.shape({
    height: PropTypes.number.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "info",
      "success",
      "warning",
      "danger",
      "transparent",
      "white",
      "rose",
      "dark"
    ]).isRequired
  })
};
