// import package
import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from 'react-router-dom';
import SocketContext from '../Context/SocketContext';


// import action
import {
  getBankDetail,
  getUPIDetail,
  getQRDetail,
  getUserSetting,
} from "../../actions/users";
import {
  getPriceConversion,
  getCurrency,
  getANNC,
} from "../../actions/commonAction";
import { getAssetData } from "../../actions/walletAction";
import { viewUserProfile, logout } from "../../actions/users";

const HelperRoute = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const socketContext = useContext(SocketContext);

  // redux-state
  const { isAuth } = useSelector((state) => state.auth);
  const currencyOption = useSelector((state) => state.currency);

  // function
  useEffect(() => {
    if (isAuth) {
      getUserSetting(dispatch);
      getBankDetail(dispatch);
      getUPIDetail(dispatch);
      getQRDetail(dispatch);
      getAssetData(dispatch);
      viewUserProfile(dispatch);
      getANNC(dispatch);
    }
  }, [isAuth]);

  useEffect(() => {
    if (!(currencyOption && currencyOption.length > 0)) {
      getCurrency(dispatch);
    }
    getPriceConversion(dispatch);


    socketContext.socket.on('FORCE_LOGOUT', (result) => {
        logout(history, dispatch)
    })

  }, []);

  return <div />;
};

export default HelperRoute;
