import jwt_decode from "jwt-decode";

// import constant
import { SET_AUTHENTICATION } from "../constant";

// import config
import { createSocketUser } from "../config/socketConnectivity";

export const decodeJwt = (token, dispatch) => {
  if (token) {
    token = token.replace("Bearer ", "");
    const decoded = jwt_decode(token);
    if (decoded) {
      createSocketUser(decoded._id);
      dispatch({
        type: SET_AUTHENTICATION,
        authData: {
          isAuth: true,
          userId: decoded._id,
        },
      });
    }
  }
};
